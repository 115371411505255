<template>
  <div class="payContainer">
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted(){
    const txId = this.$route.query.txId;
    this.$http
      .getAction('/WxOrderQuery', {
        orderNo: this.txId, 
      })
      .then((res) => {
					console.log("省行回调支付查询接口",JSON.stringify(res))
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods:{
  },
};
</script>

<style lang="less" scoped>
.imgArea{
    width: 98%;
    text-align: center;
    margin-top: 100px;
}
.backBtn {
  width: 21.4375rem;
  position: fixed;
  bottom: 0;
  padding: 2rem 1rem;
}
</style>
